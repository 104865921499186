import React, { useState } from 'react'
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from "primereact/checkbox";
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { RadioButton } from "primereact/radiobutton";
import { saveAs } from 'file-saver'

const App = () => {
  const [value, setValue] = useState('Mario Rossi');
  const [foreColor, setForeColor] = useState('#ffffff');
  const [bgColor, setBgColor] = useState('#4F46E5');
  const [size, setSize] = useState(64);
  const [rounded, setRounded] = useState(false);
  const [bold, setBold] = useState(false);
  const [uppercase, setUppercase] = useState(true);
  const [length, setLength] = useState(2);
  const [image, setImage] = useState(null);
  const [format, setFormat] = useState('png');
  
  function generate() {
    setImage(null);
    setTimeout(function() {
      
      let url = "https://ui-avatars.com/api/?name="+value.replace(" ","+")+"&size="+size+"&uppercase="+rounded+"&color="+foreColor.replace("#","");
      url+="&format=png&bold="+bold+"&uppercase="+uppercase+"&format="+format+"&length="+length+"&background="+bgColor.replace("#","");
      setImage(url);
  }, 500);

  }

  function rgbToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  function downloadImage()
  {
    saveAs(image, 'download.'+format);
  }

  return (
    <>
      <article className='bg-white border-1 border-round-3xl p-3 mx-auto my-4 border-300 shadow-4' style={{ maxWidth: "320px" }}>
        <div className="flex  mb-3 ">
          <InputText placeholder="Name" value={value} className='w-full' onChange={(e) => setValue(e.target.value)} />
        </div>
        <div className="flex  mb-3">
          <InputText placeholder="Fore color" value={foreColor} className='w-full' onChange={(e) => setForeColor(e.target.value)}/>
          <div className='border-1 border-round border-300' style={{width:"50px",height:"47px",backgroundColor:rgbToHex(foreColor)}}></div>
        </div>
        <div className="flex   mb-3">
        <InputText placeholder="Background color" value={bgColor} className='w-full' onChange={(e) => setBgColor(e.target.value)}/>
        <div className='border-1 border-round border-300' style={{width:"50px",height:"47px",backgroundColor:rgbToHex(bgColor)}}></div>
        </div>
        <div className="flex mb-3">
          <InputNumber  value={size} onValueChange={(e) => setSize(e.value)} className='w-full' placeholder='Size' max={512}/>
        </div>
        <div className="flex mb-3">
          <InputNumber  value={length} onValueChange={(e) => setLength(e.value)} className='w-full' placeholder='Length' />
        </div>
        <div className="flex mb-3">
          <Checkbox inputId="inputRounded" onChange={e => setRounded(e.checked)} checked={rounded}></Checkbox>
          <label htmlFor="inputRounded" className="ml-2 mr-3">Rounded</label>

          <Checkbox inputId="inputRounded" onChange={e => setBold(e.checked)} checked={bold}></Checkbox>
          <label htmlFor="inputRounded" className="ml-2 mr-3">Bold</label>

          <Checkbox inputId="inputRounded" onChange={e => setUppercase(e.checked)} checked={uppercase}></Checkbox>
          <label htmlFor="inputRounded" className="ml-2 mr-3">Uppercase</label>
        </div>
        
        <div className="card flex justify-content-center mb-3">
            <div className="flex flex-wrap gap-3">
                <div className="flex align-items-center">
                    <RadioButton inputId="format1" name="png" value="png" onChange={(e) => setFormat(e.value)} checked={format === 'png'} />
                    <label htmlFor="format1" className="ml-2">Png</label>
                </div>
                <div className="flex align-items-center">
                    <RadioButton inputId="format2" name="svg" value="svg" onChange={(e) => setFormat(e.value)} checked={format === 'svg'} />
                    <label htmlFor="format2" className="ml-2">Svg</label>
                </div>
            </div>
        </div>

        <Button label="Generate" icon="pi pi-image" className='w-full mb-2' onClick={generate} />
        <Button label="Download" icon="pi pi-download" className='w-full mb-2' onClick={downloadImage} />

        <div className='text-center p-3'> 
        <Image src={image} alt="Image" width='64px'/>
      </div>
      </article>
   
    </>
  );

}

export default App;