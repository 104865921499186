import React, { useState } from 'react'
import './Footer.css';

const Footer=()=>
{
    return <>
        <footer>
            Powered with ❤️ by <a href="https://www.adlgroup.it/" target='_blank'>ADL Ingegneria Informatica</a>
        </footer>
    </>
}

export default Footer;